import { useEffect, useRef, useState } from "preact/hooks";
import { getSiteConfig } from "../site.tsx";

export default function MilkyWayCanvas() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const { theme } = getSiteConfig();
  
  // Add effect to handle theme changes
  useEffect(() => {
    // Listen for theme changes
    const handleThemeChange = (e: CustomEvent) => {
      if (canvasRef.current && canvasRef.current.getContext("webgl")) {
        const gl = canvasRef.current.getContext("webgl");
        const isDark = e.detail?.isDark || document.documentElement.classList.contains('dark');
        
        // Set background color based on theme
        if (isDark) {
          gl.clearColor(0.0, 0.0, 0.05, 1.0); // Dark blue background for dark mode
        } else {
          gl.clearColor(0.95, 0.95, 0.95, 1.0); // Light grey background for light mode
        }
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }
    };

    // Check initial theme
    const isDark = document.documentElement.classList.contains('dark');
    if (canvasRef.current && canvasRef.current.getContext("webgl")) {
      const gl = canvasRef.current.getContext("webgl");
      if (isDark) {
        gl.clearColor(0.0, 0.0, 0.05, 1.0); // Dark blue background for dark mode
      } else {
        gl.clearColor(0.95, 0.95, 0.95, 1.0); // Light grey background for light mode
      }
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    // Add event listener for theme changes
    window.addEventListener('themechange', handleThemeChange as EventListener);
    
    return () => {
      window.removeEventListener('themechange', handleThemeChange as EventListener);
    };
  }, []);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    // Initialize WebGL context
    const gl = canvas.getContext("webgl");
    if (!gl) {
      setError("WebGL is not supported in your browser");
      setIsLoading(false);
      return;
    }
    
    // Set canvas size to fill the container
    const resizeCanvas = () => {
      const container = canvas.parentElement;
      if (container) {
        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;
        gl.viewport(0, 0, canvas.width, canvas.height);
      }
    };
    
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    
    // Vertex shader program with improved star rendering
    const vsSource = `
      attribute vec3 aPosition;
      attribute vec3 aColor;
      attribute float aSize;
      
      uniform mat4 uModelViewMatrix;
      uniform mat4 uProjectionMatrix;
      uniform float uTime;
      
      varying vec3 vColor;
      varying float vBrightness;
      
      void main() {
        // Add subtle oscillation to star brightness based on time
        float brightness = 0.8 + 0.2 * sin(uTime * 0.5 + aPosition.x * 0.1);
        
        gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aPosition, 1.0);
        gl_PointSize = aSize * brightness;
        vColor = aColor;
        vBrightness = brightness;
      }
    `;
    
    // Fragment shader program with improved star glow
    const fsSource = `
      precision mediump float;
      varying vec3 vColor;
      varying float vBrightness;
      
      void main() {
        float distance = length(gl_PointCoord - vec2(0.5, 0.5));
        if (distance > 0.5) {
          discard;
        }
        
        // Create a more realistic star glow effect
        float intensity = smoothstep(0.5, 0.0, distance);
        float glow = pow(intensity, 1.5) * vBrightness;
        
        gl_FragColor = vec4(vColor * glow, glow);
      }
    `;
    
    try {
      // Create shader program
      const vertexShader = createShader(gl, gl.VERTEX_SHADER, vsSource);
      const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fsSource);
      
      if (!vertexShader || !fragmentShader) {
        throw new Error("Failed to compile shaders");
      }
      
      const program = createProgram(gl, vertexShader, fragmentShader);
      
      if (!program) {
        throw new Error("Failed to create shader program");
      }
      
      // Get attribute and uniform locations
      const positionAttributeLocation = gl.getAttribLocation(program, "aPosition");
      const colorAttributeLocation = gl.getAttribLocation(program, "aColor");
      const sizeAttributeLocation = gl.getAttribLocation(program, "aSize");
      const modelViewMatrixLocation = gl.getUniformLocation(program, "uModelViewMatrix");
      const projectionMatrixLocation = gl.getUniformLocation(program, "uProjectionMatrix");
      const timeLocation = gl.getUniformLocation(program, "uTime");
      
      // Create buffers and load data
      const numStars = 15000; // Increased number of stars
      const positions = new Float32Array(numStars * 3);
      const colors = new Float32Array(numStars * 3);
      const sizes = new Float32Array(numStars);
      
      // Use theme colors for the galaxy
      const primaryColor = hexToRgb(theme.primary);
      const accentColor = hexToRgb(theme.accent);
      
      // Generate random stars in a spiral galaxy pattern with multiple arms
      const numArms = 5; // Number of spiral arms
      
      for (let i = 0; i < numStars; i++) {
        // Determine which arm this star belongs to
        const armIndex = Math.floor(Math.random() * numArms);
        const armOffset = (2 * Math.PI * armIndex) / numArms;
        
        // Spiral galaxy distribution
        const distance = Math.pow(Math.random(), 0.5) * 50;
        const twist = 4.0; // Controls the spiral tightness
        const angle = armOffset + twist * Math.log(distance);
        
        // Add some randomness to make it look more natural
        const randomOffset = (Math.random() - 0.5) * (5 + distance * 0.1);
        
        // Position
        positions[i * 3] = distance * Math.cos(angle) + randomOffset;
        positions[i * 3 + 1] = (Math.random() - 0.5) * 5; // Thickness of the galaxy
        positions[i * 3 + 2] = distance * Math.sin(angle) + randomOffset;
        
        // Color - use theme colors with variations
        const distanceRatio = Math.min(1.0, distance / 50);
        if (distance < 15) {
          // Core stars - use accent color with variations
          colors[i * 3] = accentColor.r / 255 + Math.random() * 0.2;
          colors[i * 3 + 1] = accentColor.g / 255 + Math.random() * 0.2;
          colors[i * 3 + 2] = accentColor.b / 255 + Math.random() * 0.2;
        } else {
          // Outer stars - use primary color with variations
          colors[i * 3] = primaryColor.r / 255 + Math.random() * 0.3;
          colors[i * 3 + 1] = primaryColor.g / 255 + Math.random() * 0.3;
          colors[i * 3 + 2] = primaryColor.b / 255 + Math.random() * 0.3;
        }
        
        // Size - brighter stars in the center and some random large stars
        const isBrightStar = Math.random() < 0.05; // 5% chance of being a bright star
        sizes[i] = (1.0 - distanceRatio * 0.7) * (isBrightStar ? 3.0 + Math.random() * 2.0 : 1.0 + Math.random() * 1.5);
      }
      
      // Add some dust particles in the galaxy plane
      const dustStartIndex = Math.floor(numStars * 0.8); // Last 20% of stars are dust
      for (let i = dustStartIndex; i < numStars; i++) {
        const angle = Math.random() * 2 * Math.PI;
        const distance = Math.random() * 60;
        
        positions[i * 3] = distance * Math.cos(angle);
        positions[i * 3 + 1] = (Math.random() - 0.5) * 2; // Very thin along the plane
        positions[i * 3 + 2] = distance * Math.sin(angle);
        
        // Dust is dimmer and more reddish
        colors[i * 3] = 0.8 + Math.random() * 0.2; // Red
        colors[i * 3 + 1] = 0.3 + Math.random() * 0.3; // Green
        colors[i * 3 + 2] = 0.1 + Math.random() * 0.2; // Blue
        
        // Dust particles are smaller
        sizes[i] = 0.5 + Math.random() * 1.0;
      }
    
      // Create and bind position buffer
      const positionBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);
      
      // Create and bind color buffer
      const colorBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, colors, gl.STATIC_DRAW);
      
      // Create and bind size buffer
      const sizeBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, sizeBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, sizes, gl.STATIC_DRAW);
      
      // Matrix math functions
      const mat4 = {
        create: function() {
          return new Float32Array([
            1, 0, 0, 0,
            0, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
          ]);
        },
        perspective: function(out, fovy, aspect, near, far) {
          const f = 1.0 / Math.tan(fovy / 2);
          out[0] = f / aspect;
          out[1] = 0;
          out[2] = 0;
          out[3] = 0;
          out[4] = 0;
          out[5] = f;
          out[6] = 0;
          out[7] = 0;
          out[8] = 0;
          out[9] = 0;
          out[10] = (far + near) / (near - far);
          out[11] = -1;
          out[12] = 0;
          out[13] = 0;
          out[14] = (2 * far * near) / (near - far);
          out[15] = 0;
          return out;
        },
        identity: function(out) {
          out[0] = 1;
          out[1] = 0;
          out[2] = 0;
          out[3] = 0;
          out[4] = 0;
          out[5] = 1;
          out[6] = 0;
          out[7] = 0;
          out[8] = 0;
          out[9] = 0;
          out[10] = 1;
          out[11] = 0;
          out[12] = 0;
          out[13] = 0;
          out[14] = 0;
          out[15] = 1;
          return out;
        },
        translate: function(out, a, v) {
          const x = v[0], y = v[1], z = v[2];
          
          out[12] = a[0] * x + a[4] * y + a[8] * z + a[12];
          out[13] = a[1] * x + a[5] * y + a[9] * z + a[13];
          out[14] = a[2] * x + a[6] * y + a[10] * z + a[14];
          out[15] = a[3] * x + a[7] * y + a[11] * z + a[15];
          
          return out;
        },
        rotateX: function(out, a, rad) {
          const s = Math.sin(rad);
          const c = Math.cos(rad);
          
          const a10 = a[4];
          const a11 = a[5];
          const a12 = a[6];
          const a13 = a[7];
          const a20 = a[8];
          const a21 = a[9];
          const a22 = a[10];
          const a23 = a[11];
          
          // Perform axis-specific matrix multiplication
          out[4] = a10 * c + a20 * s;
          out[5] = a11 * c + a21 * s;
          out[6] = a12 * c + a22 * s;
          out[7] = a13 * c + a23 * s;
          out[8] = a20 * c - a10 * s;
          out[9] = a21 * c - a11 * s;
          out[10] = a22 * c - a12 * s;
          out[11] = a23 * c - a13 * s;
          
          return out;
        },
        rotateY: function(out, a, rad) {
          const s = Math.sin(rad);
          const c = Math.cos(rad);
          
          const a00 = a[0];
          const a01 = a[1];
          const a02 = a[2];
          const a03 = a[3];
          const a20 = a[8];
          const a21 = a[9];
          const a22 = a[10];
          const a23 = a[11];
          
          // Perform axis-specific matrix multiplication
          out[0] = a00 * c - a20 * s;
          out[1] = a01 * c - a21 * s;
          out[2] = a02 * c - a22 * s;
          out[3] = a03 * c - a23 * s;
          out[8] = a00 * s + a20 * c;
          out[9] = a01 * s + a21 * c;
          out[10] = a02 * s + a22 * c;
          out[11] = a03 * s + a23 * c;
          
          return out;
        },
        rotateZ: function(out, a, rad) {
          const s = Math.sin(rad);
          const c = Math.cos(rad);
          
          const a00 = a[0];
          const a01 = a[1];
          const a02 = a[2];
          const a03 = a[3];
          const a10 = a[4];
          const a11 = a[5];
          const a12 = a[6];
          const a13 = a[7];
          
          // Perform axis-specific matrix multiplication
          out[0] = a00 * c + a10 * s;
          out[1] = a01 * c + a11 * s;
          out[2] = a02 * c + a12 * s;
          out[3] = a03 * c + a13 * s;
          out[4] = a10 * c - a00 * s;
          out[5] = a11 * c - a01 * s;
          out[6] = a12 * c - a02 * s;
          out[7] = a13 * c - a03 * s;
          
          return out;
        }
      };
    
      // Animation state
      let rotation = 0;
      let tilt = Math.PI * 0.1; // Slight tilt to the galaxy
      let lastTime = 0;
      let cameraDistance = -120;
      let cameraHeight = 30;
      
      // Interactive controls state
      let previousMouseX = 0;
      let previousMouseY = 0;
      let rotationSpeed = 0.1;
      
      // Add mouse event listeners for interactive rotation
      canvas.addEventListener('mousedown', (e) => {
        setIsDragging(true);
        previousMouseX = e.clientX;
        previousMouseY = e.clientY;
      });
      
      canvas.addEventListener('mousemove', (e) => {
        if (isDragging) {
          const deltaX = e.clientX - previousMouseX;
          const deltaY = e.clientY - previousMouseY;
          
          rotation += deltaX * 0.01;
          tilt += deltaY * 0.01;
          
          // Limit tilt angle to prevent flipping
          tilt = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, tilt));
          
          previousMouseX = e.clientX;
          previousMouseY = e.clientY;
        }
      });
      
      canvas.addEventListener('mouseup', () => {
        setIsDragging(false);
      });
      
      canvas.addEventListener('mouseleave', () => {
        setIsDragging(false);
      });
      
      // Add wheel event for zoom
      canvas.addEventListener('wheel', (e) => {
        e.preventDefault();
        cameraDistance += e.deltaY * 0.1;
        // Limit zoom range
        cameraDistance = Math.max(-200, Math.min(-50, cameraDistance));
      });
      
      // Render function
      function render(time) {
        // Convert time to seconds
        time *= 0.001;
        const deltaTime = time - lastTime;
        lastTime = time;
        
        // Update rotation if not being controlled by mouse
        if (!isDragging) {
          rotation += deltaTime * rotationSpeed;
        }
        
        // Clear canvas with theme-appropriate color
        const isDark = document.documentElement.classList.contains('dark');
        if (isDark) {
          gl.clearColor(0.0, 0.0, 0.05, 1.0); // Dark blue background for dark mode
        } else {
          gl.clearColor(0.95, 0.95, 0.95, 1.0); // Light grey background for light mode
        }
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        
        // Enable blending for star glow
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE);
        
        // Use the program
        gl.useProgram(program);
        
        // Set time uniform for star twinkling
        gl.uniform1f(timeLocation, time);
        
        // Set up matrices
        const projectionMatrix = mat4.create();
        const modelViewMatrix = mat4.create();
        
        // Set up perspective
        mat4.perspective(projectionMatrix, 45 * Math.PI / 180, canvas.width / canvas.height, 0.1, 1000.0);
        
        // Set up camera position
        mat4.identity(modelViewMatrix);
        mat4.translate(modelViewMatrix, modelViewMatrix, [0, cameraHeight, cameraDistance]);
        mat4.rotateX(modelViewMatrix, modelViewMatrix, tilt);
        mat4.rotateY(modelViewMatrix, modelViewMatrix, rotation);
        
        // Set uniforms
        gl.uniformMatrix4fv(projectionMatrixLocation, false, projectionMatrix);
        gl.uniformMatrix4fv(modelViewMatrixLocation, false, modelViewMatrix);
        
        // Set up position attribute
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.vertexAttribPointer(positionAttributeLocation, 3, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(positionAttributeLocation);
        
        // Set up color attribute
        gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
        gl.vertexAttribPointer(colorAttributeLocation, 3, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(colorAttributeLocation);
        
        // Set up size attribute
        gl.bindBuffer(gl.ARRAY_BUFFER, sizeBuffer);
        gl.vertexAttribPointer(sizeAttributeLocation, 1, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(sizeAttributeLocation);
        
        // Draw the stars
        gl.drawArrays(gl.POINTS, 0, numStars);
        
        // Request next frame
        requestAnimationFrame(render);
      }
      
      // Start the animation
      requestAnimationFrame(render);
      
      // Loading complete
      setIsLoading(false);
      
    } catch (err) {
      console.error("WebGL error:", err);
      setError(err.message || "An error occurred while initializing WebGL");
      setIsLoading(false);
    }
    
    // Cleanup function
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (gl) {
        // Clean up WebGL resources
        try {
          if (program) gl.deleteProgram(program);
          if (vertexShader) gl.deleteShader(vertexShader);
          if (fragmentShader) gl.deleteShader(fragmentShader);
          if (positionBuffer) gl.deleteBuffer(positionBuffer);
          if (colorBuffer) gl.deleteBuffer(colorBuffer);
          if (sizeBuffer) gl.deleteBuffer(sizeBuffer);
        } catch (e) {
          console.error("Error during cleanup:", e);
        }
      }
    };
  }, [theme, isDragging]); // Re-run when theme or isDragging changes
  
  // Helper functions for WebGL
  function createShader(gl: WebGLRenderingContext, type: number, source: string) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    
    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (!success) {
      console.error("Could not compile shader:", gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
    }
    
    return shader;
  }
  
  function createProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {
    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    
    const success = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (!success) {
      console.error("Could not link program:", gl.getProgramInfoLog(program));
      gl.deleteProgram(program);
      return null;
    }
    
    return program;
  }
  
  // Helper function to convert hex color to RGB
  function hexToRgb(hex: string) {
    // Remove # if present
    hex = hex.replace(/^#/, '');
    
    // Parse hex values
    let r, g, b;
    if (hex.length === 3) {
      // #RGB format
      r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
      g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
      b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else {
      // #RRGGBB format
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }
    
    return { r, g, b };
  }
  
  return (
    <div class="relative w-full h-full">
      {isLoading && (
        <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 z-10">
          <div class="text-white text-xl">Loading galaxy simulation...</div>
        </div>
      )}
      
      {error && (
        <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 z-10">
          <div class="bg-red-800 text-white p-4 rounded-lg max-w-md">
            <h3 class="text-xl font-bold mb-2">Error</h3>
            <p>{error}</p>
            <p class="mt-2 text-sm">Your browser may not support WebGL, or it might be disabled.</p>
          </div>
        </div>
      )}
      
      <canvas 
        ref={canvasRef} 
        style={{ 
          width: "100%", 
          height: "100%", 
          display: "block",
          cursor: isDragging ? "grabbing" : "grab"
        }}
      />
      
      <div class="absolute bottom-4 left-4 text-white text-sm bg-black bg-opacity-50 p-2 rounded">
        <p>Drag to rotate • Scroll to zoom</p>
      </div>
    </div>
  );
}
